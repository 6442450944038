import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from '../../../common/src/theme/charity';
import { ResetCSS } from '../../../common/src/assets/css/style';
import { DrawerProvider } from '../../../common/src/contexts/DrawerContext';
import Navbar from '../Navbar';
import Footer from '../Footer';
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapperOtherPages
} from '../charity.style';
import SEO from '../../../components/seo';
import SectionWrapper, { ChapterContent, SectionHeader } from './chapterGeneral.style';
import Container from '../../../common/src/components/UI/Container';

export default ({ children }) => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <ResetCSS/>
        <GlobalStyle/>
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <ContentWrapperOtherPages>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar/>
              </DrawerProvider>
            </Sticky>
            <SectionWrapper id="blog">
              <Container width="1260px">
                <SectionHeader>
                  <ChapterContent>
                    {children}
                  </ChapterContent>
                </SectionHeader>
              </Container>
            </SectionWrapper>
          </ContentWrapperOtherPages>
          <Footer/>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
