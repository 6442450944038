import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter04 from '../common/src/assets/image/charity/specter/kapitola_04_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Štvrtá kapitola: Vyvážanie revolúcie | Ako duch komunizmu vládne nášmu svetu"
           description="Štvrtá kapitola: Vyvážanie revolúcie"
           image={imageChapter04}/>
      <h1>Štvrtá kapitola: Vyvážanie revolúcie</h1>
      <h1>Úvod</h1>
      <p>Kult komunizmu sa po celom svete šíri pomocou násilia a podvodov. Kým komunistické superveľmoci, ako Sovietsky
        zväz alebo Čína, použili vojenskú silu na vnútenie svojho politického systému slabším krajinám, nemalo by sa
        zabúdať na to, že násilné dobývanie sveta komunistickým režimom bolo podporované – a do značnej miery umožnené –
        efektívnym využívaním propagandy. Čínska komunistická strana (ČKS) v posledných rokoch pokračovala v tejto
        stratégii a vlievala miliardy do svojho programu Veľkej vonkajšej propagácie. <a href="#_edn1"
                                                                                         id="_ednref1">[1]</a></p>
      <p>Táto kapitola predstavuje úvod do toho, ako komunistické režimy na východe, najmä Čínska ľudová republika
        (ČĽR), šírili svoju ideológiu a vplyv v Ázii, Afrike, Južnej Amerike a východnej Európe, najmä počas studenej
        vojny.</p>
      <h2>1. Vyvážanie revolúcie do Ázie</h2>
      <p>Čínske komunistické hnutie vďačí za svoj úspech Sovietskemu zväzu. V roku 1919 založil boľševický režim Tretiu
        internacionálu (tzv. Kominternu), ako prostriedok pre organizovanie ďalších revolúcií na svete. V apríli 1920
        vycestoval do Číny jej zástupca Grigorij Vojtinskij. O mesiac neskôr bol v Šanghaji založený úrad, ktorý mal
        pomôcť zriadiť ČKS. Niekoľko rokov bola ČKS závislá od financovania Sovietskym zväzom a slúžila ako orgán
        Komunistickej strany Sovietskeho zväzu.<a href="#_edn2" id="_ednref2">[2]</a> V ďalších troch desaťročiach ČKS
        pokračovala v&nbsp;podporovaní sovietskych záujmov v Číne.</p>
      <p>Víťazstvo ČKS v Číne bolo nepriamo spojené s ľavicovým vplyvom v Spojených štátoch. Americkí úradníci na
        ministerstve zahraničia a ďalších inštitúciách, ktorí&nbsp; podporovali čínskych komunistov, podfarbili
        pochopenie Washingtonu politickej situácie v Číne počas a po druhej svetovej vojne. Ich vplyv viedol USA k
        prerušeniu pomoci nacionalistickej vláde Čankajška, zatiaľ čo Sovietsky zväz posilnil podporu ČKS.</p>
      <p>Prezident spojených štátov Truman sa po druhej svetovej vojne rozhodol tiež stiahnuť z Ázie. V roku 1948 začala
        americká armáda ustupovať z Južnej Kórey a 5. januára 1950 Truman oznámil, že USA nebude do záležitostí v
        Taiwanskom prielive zasahovať. Toto tiež znamenalo ukončenie vojenskej podpory – dokonca aj v&nbsp;prípade vojny
        – nacionalistickej Číne, ktorá sa stiahla na ostrov Taiwan a čelila invázii komunistov z&nbsp;pevninskej Číny.<a
          href="#_edn3" id="_ednref3">[3]</a> Minister zahraničných vecí spojených štátov Dean Acheson potvrdil
        Trumanovu politiku a uviedol, že Kórejský polostrov leží mimo „obranného pásma“ Spojených štátov.<a
          href="#_edn4" id="_ednref4">[4]</a> Táto nezasahujúca politika poskytla komunistickej strane príležitosť k
        expanzii v celej Ázii. Zmenila sa až potom, ako OSN v júni 1950 hlasovala za obranu Južnej Kórey po napadnutí
        Severnou Kóreou.</p>
      <p>ČKS spravila z vyvážania revolúcie základ svojej zahraničnej politiky. Okrem výcviku partizánskych jednotiek v
        rôznych krajinách, poskytovania výzbroje a vysielania jednotiek, ktoré mali bojovať proti legitímnym vládam,
        poskytovala tiež významné finančné prostriedky na podporu rozličných ľavicových povstaní. Uprostred kultúrnej
        revolúcie v roku 1973, ČKS poskytla zahraničnú pomoc na tieto účely vo výške takmer 7 % štátneho rozpočtu.</p>
      <p>Extravagantný projekt ČKS vývozu revolúcie bol zaplatený majetkom – a často životmi – čínskych ľudí.</p>
      <p>Podľa čínskeho učenca Čchien Ja-pchinga, ktorý mal prístup k dôverným dokumentom vydaným ministerstvom
        zahraničia, „poslala ČKS v roku 1960 desaťtisíc ton ryže Guinei a pätnásť tisíc ton obilia Albánsku. Medzi rokmi
        1950 a 1964 tvorili výdavky na zahraničnú pomoc 10,8 miliardy jüanov. Z toho najväčšia časť bola poskytnutá v
        dobe čínskeho hladomoru.“ Keď v&nbsp;rokoch 1958 až 1962 zomreli počas hladomoru desiatky miliónov Číňanov,
        zahraničná pomoc predstavovala sumu 2,36 miliardy jüanov. Keby boli tieto prostriedky použité na zakúpenie
        jedla, mohlo to zachrániť pred smrťou hladom nespočítateľné množstvo Číňanov.<a href="#_edn5"
                                                                                        id="_ednref5">[5]</a></p>
      <h3>a) Kórejská vojna</h3>
      <p>Komunizmus vo svete hľadá cesty, ako získať nadvládu a vodcov s hladom po moci ako sú Jozef Stalin, Mao
        Ce-tung, Kim Ir-sen aj Ho Či Min, aby rozšírili jeho diabolskú ideológiu na väčšie územie a&nbsp;medzi viac
        ľudí.</p>
      <p>Po rozsiahlom plánovaní napadla 25. júna 1950 Severná Kórea Južnú, pričom Soul padol do troch dní. Za šesť
        týždňov okupovala Severná Kórea celý polostrov. Než vojna vypukla, nazhromaždil Mao v marci 1950 pozdĺž
        čínsko-kórejskej hranice veľké množstvo jednotiek pripravených na boj. Keď sily OSN prenikli hlboko na
        severokórejské územie, ČKS poslala do akcie ľudovú armádu dobrovoľníkov, čím zachránila komunistický režim Kim
        Il Sunga pred úplným zničením. Vojna trvala tri roky a vyžiadala si milióny životov na oboch stranách.
        Komunistická Čína utrpela asi milión obetí.<a href="#_edn6" id="_ednref6">[6]</a></p>
      <p>Okrem záchrany Kimovho režimu mala ČKS ďalší motív na účasť v konflikte: Počas čínskej občianskej vojny 1,7
        milióna vojakov vystúpilo zo síl Kuomintangu a vstúpilo do ČKS. Kórejská vojna poskytla vhodnú príležitosť ako
        sa zbaviť týchto politicky nespoľahlivých jednotiek.<a href="#_edn7" id="_ednref7">[7]</a></p>
      <p>Kým čínska a sovietska komunistická strana súperili o nadvládu nad Severnou Kóreou, Sever profitoval z oboch
        strán. Napríklad keď v roku 1966 Kim Ir-sen navštívil Čínu, pozoroval práve prebiehajúce práce na pekinskom
        metre. Požiadal Maa o identické metro, ktoré by sa malo vybudovať v Pchjongjangu – zadarmo. Mao sa rozhodol, že
        okamžite preruší práce na stavbe v Pekingu a vyslal do Pchjongjangu personál aj s vybavením, vrátane dvoch
        divízií železničných jednotiek Ľudovej oslobodeneckej armády a mnohých konštruktérov, spolu viac ako 10&nbsp;000
        ľudí. Severná Kórea neminula za výstavbu ani halier a dokonca na ňu nepoužila ani svojich ľudí. Namiesto toho
        ešte od čínskej strany vyžadovala záruku ochrany metra v čase vojny. Nakoniec sa metro v Pchjongjangu s
        priemernou hĺbkou 90 metrov a najväčšou hĺbkou 150 metrov stalo svojho času jedným z najhlbších na svete. Keď
        bola stavba hotová, povedal Kim Ir-sen verejnosti, že metro bolo navrhnuté a postavené Kórejcami. Navyše, Kim
        často obchádzal Peking a žiadal Sovietsky zväz o peniaze a materiál. Po skončení kórejskej vojny ČKS úmyselne
        zanechala v Severnej Kórei niekoľko svojich ľudí. Ich úlohou bolo udržiavať medzi Severom a ČLR úzke vzťahy. Kim
        ale nechal týchto čínskych komunistov buď zabiť alebo uväzniť, a&nbsp;tak ČĽR nakoniec stratila na všetkých
        frontoch.<a href="#_edn8" id="_ednref8">[8]</a></p>
      <p>Severná Kórea zrkadlí hrôzy komunizmu vnúteného zvonka. Kimov režim je jeden z najbrutálnejších a
        najrepresívnejších na zemi a severokórejský ľud žije v obrovskej chudobe.</p>
      <p>Po páde Sovietskeho zväzu ČKS drasticky obmedzila svoju pomoc Severnej Kórei. V deväťdesiatych rokoch trpeli
        Severokórejčania nedostatkom jedla. V roku 2007 informovala mimovládna nezisková organizácia Asociácia
        severokórejských dezertérov, že počas 60 rokov trvajúcej vlády rodu Kim zomrelo od hladu a na súvisiace choroby
        minimálne 3,5 milióna ľudí.<a href="#_edn9" id="_ednref9">[9]</a></p>
      <h3>b) Vietnamská vojna</h3>
      <p>Ešte pred kórejskou vojnou pomáhala ČKS Komunistickej strane Vietnamu v boji proti francúzskej koloniálnej
        vláde. V roku 1954 Francúzi utrpeli veľkú porážku v Dien Bien Phu, čo vyvrcholilo ženevskou konferenciou a
        konfrontáciou medzi Severným a Južným Vietnamom. Po francúzskom ústupe z Indočíny severný Vietnam napadol juh po
        Ho Či Minovej ceste cez Laos a Kambodžu. Od roku 1964 do roku 1973 sa Spojené štáty tejto vojny zúčastnili v
        snahe obmedziť šírenie komunizmu na juh. Po druhej svetovej vojne išlo o najväčší konflikt na jednom území.</p>
      <p>Mao vyslal „poradcov“ do vietnamskej komunistickej strany &nbsp;už v roku 1950. Najvyšším predstaviteľom
        poradenskej skupiny bol Wej Kuo-čching, generál Ľudovej oslobodzovacej armády. Poradenská skupina pre pozemkovú
        reformu vyslaná ČKS zadržala a popravila vo Vietname desiatky tisíc statkárov a „bohatých roľníkov“, čím na
        severe vyvolala hladomor a nepokoje roľníkov. ČKS potom spolu s Komunistickou stranou Vietnamu potlačila
        povstanie a spustili nápravné ideologické hnutia strany a armády, podobné nápravnému hnutiu v Jen-nane
        v&nbsp;rokoch 1942 – 1944. Mao vo veľkej miere pomáhal Vietnamu, zatiaľ čo desiatky miliónov ľudí v Číne
        umierali od hladu. Urobil to preto, aby súťažil so Sovietmi o vplyv vo Vietname a tiež aby posilnil svoju
        autoritu v rámci ČKS.</p>
      <p>Maovu katastrofálnu politiku Veľkého skoku vpred ukončil v roku 1962 na ľudovom zhromaždení so 7 tisíc ľuďmi
        Liou Šao-čchi, podpredseda ČKS, ktorý sa zároveň chystal ozdraviť ekonomiku. Toto by obmedzilo Maovu moc,
        a&nbsp;tak aby si moc udržal, zapojil ešte viac Čínu do vietnamskej vojny. Liou, ktorý nad Ľudovou
        oslobodzovacou armádou nemal žiadnu kontrolu, bol so svojím plánom na obnovu ekonomiky zatlačený do pozadia.</p>
      <p>Následne vyslal Mao v roku 1963 do Vietnamu Luo Žuej-čchinga a Lin Piaa. Liou sľúbil Ho Či Minovi, že ČKS
        pokryje výdavky z vietnamskej vojny, so slovami: „Ak bude vojna, môžete Čínu považovať za domáci front.“ ČKS
        splnila tento sľub. Do roku 1975 dosiahla celková pomoc ČKS Vietnamu 20 miliárd dolárov a do severného Vietnamu
        boli nasadené stovky tisíc čínskych vojakov, ktorí slúžili v rôznych bojových a podporných úlohách.</p>
      <p>Je iróniou, že pomoc, ktorú ČKS požadovala od vietnamskej komunistickej strany, sa stala miestom politického
        zlomu medzi ČĽR a severným Vietnamom. Aby udržala boj severných Vietnamcov proti Spojeným štátom, ČKS ich
        neustále zásobovala zbraňami a iným vojnovým materiálom. Medzitým vietnamská komunistická strana dúfala, že
        vojna sa skončí rýchlejšie a v roku 1969 sa pripojila k parížskym mierovým rokovaniam vedeným USA. Z týchto
        rokovaní bola Čína vylúčená, čo znamená, že vietnamská komunistická strana išla proti záujmu ČKS udržať USA
        zaneprázdnené Vietnamom.</p>
      <p>V 70. rokoch minulého storočia, po pokuse útek a vražde popredného vojenského vodcu ČKS Lin Piao, potreboval
        Mao naliehavo znovu potvrdiť svoju politickú autoritu. Okrem toho čínsko-sovietske vzťahy výrazne ochladli po
        niekoľkých vojenských zrážkach medzi dvoma mocnosťami v roku 1969 pozdĺž rieky Ussuri. Aby Mao čelil sovietskej
        hrozbe, spolupracoval so Spojenými štátmi a vyzval prezidenta USA Richarda Nixona, aby navštívil Čínu.</p>
      <p>V roku 1973, aby vyšli v ústrety domácemu protivojnovému hnutiu (ktoré bolo v skutočnosti iniciované
        komunistami), stiahli Spojené štáty svoje jednotky z Vietnamu. 30. apríla 1975 obsadil Severný Vietnam Saigon a
        prevzal tak kontrolu nad Južným Vietnamom. Na príkaz ČKS spustila Komunistická strana Vietnamu kontrarevolučné
        čistky podobné kampaniam čínskych komunistov. Viac ako dva milióny ľudí z Južného Vietnamu riskovalo svoj život,
        aby utiekli z krajiny. Stali sa tak najväčšou skupinou utečencov v Ázii počas studenej vojny.</p>
      <h3>c) Červení Khméri</h3>
      <p>Po zjednotení krajiny a podpísaní mierovej dohody s Washingtonom sa Vietnam dištancoval od vplyvu Pekingu a
        nadviazal pevnejšie vzťahy so Sovietskym zväzom. Táto situácia bola pre Maa nepríjemná, a tak sa rozhodol, že na
        Vietnam zatlačí pomocou kambodžského režimu Červených Khmérov, ktorý bol naklonený ČKS, avšak mal spory s
        Vietnamom.</p>
      <p>V roku 1955 začala komunistická strana Číny podporovať Komunistickú stranu Kampučie (všeobecne známu ako
        Červení Khméri). Vodcovia Khmérov vtedy dostávali výcvik v Číne. V&nbsp;roku 1963 sa s&nbsp;Maovým súhlasom
        dostal k&nbsp;moci Pol Pot ako vodca khmérskeho režimu. Len v roku 1970 poskytla ČKS Červeným Khmérom zbrane a
        vybavenie pre tridsaťtisíc ľudí. Kambodža, destabilizovaná vojnou vo Vietname, padla v roku 1975 do rúk
        Červených Khmérov.</p>
      <p>Pol Potova vláda bola extrémne brutálna. Zrušil menu, nariadil všetkým obyvateľom miest, aby sa pripojili ku
        oddielom nútených prác na vidieku, a nechal zavraždiť intelektuálov, aby krajinu zbavil „západného“ vplyvu. Za
        niečo viac ako tri roky viac ako štvrtina kambodžskej populácie zahynula hladom alebo bola zavraždená na
        neslávne známych „vražedných poliach“. Červeným Khmérom nestačilo terorizovanie svojich vlastných občanov, ale
        opakovane vpadli do južného Vietnamu a dopustili sa viacerých masakrov vo vietnamských pohraničných dedinách.
        Vietnam s podporou Sovietov napadol Kambodžu v decembri 1978. Po troch rokoch života v pekle kambodžskí
        obyvatelia privítali vietnamskú armádu. Iba mesiaci bojov boli Červení Khméri vyhnaní z hlavného mesta Phnom
        Penhu a&nbsp;boli prinútení utiecť do hôr, kde bojovali ako partizáni. Vietnamská represívna vojna proti Khmérom
        rozzúrila vtedajšieho čínskeho vodcu Deng Xiaopinga. Začiatkom roku 1979 nariadil Deng Čískej ľudovej
        oslobodzovacej armáde, aby zahájila „protiútok“ proti Vietnamu, čo malo za následok trojtýždennú vojnu, o ktorej
        mnohí historici tvrdia, že ju Vietnam vyhral. Počas osemdesiatych rokov ČKS opakovane uskutočňovala útoky na
        Vietnam.</p>
      <p>Pol Potovo nevypočítateľné správanie viedlo k&nbsp;rozporom vo vnútri Červených Khmérov a v roku 1997 bol
        zatknutý khmérskym veliteľom Ta Moke. Na verejnom súdnom procese dostal doživotný trest odňatia slobody. O rok
        neskôr zomrel na infarkt. Napriek opakovaným pokusom ČKS o zasahovanie do procesu odsúdil v roku 2014 mimoriadny
        súd v Kambodži dvoch vodcov Khmérov, Khieu Samphan a Nuon Chea, na doživotie.</p>
      <h3>d) Vzostup a pád infiltrácie ČKS v juhovýchodnej Ázii</h3>
      <p>Okrem svojich akcií v bývalých francúzskych kolóniách Indočíny vyvinula ČKS veľké úsilie na pomoc komunistickým
        povstaniam v juhovýchodnej Ázii. Tieto komunistické hnutia boli obzvlášť aktívne v 50. a 60. rokoch 20.
        storočia, po ktorých boli miestnymi vládami porazené alebo marginalizované.</p>
      <p>Export revolúcie ČKS mal bolestivý dopad aj na čínsku populáciu mimo pevninskej Číny. Vypuklo mnoho
        protičínskych incidentov, v ktorých boli zavraždené tisíce Číňanov žijúcich v zahraničí a bolo im obmedzené
        právo na obchodovanie a vzdelávanie.</p>
      <p>Typickým príkladom je Indonézia. Počas 50. a 60. rokov 20. storočia poskytla ČKS významnú finančnú aj vojenskú
        pomoc Indonézii, aby tak podporila tamojšiu Komunistickú stranu Indonézie (Partai Komunis Indonesia, PKI). PKI
        bola v tom čase najväčšou politickou skupinou s tromi miliónmi priamych členov v roku 1965 a ďalšími dvadsiatimi
        dvoma miliónmi členov rozptýlenými medzi organizáciami naprieč indonézskou vládou, politickým systémom, vrátane
        mnohých, ktorí mali úzke väzby s prezidentom Sukarnom.</p>
      <p>Mao v tom čase kritizoval Sovietsky zväz za podporovanie „revizionistov“, t. j. odklonu od prísnej marxistickej
        doktríny a dôrazne nabádal PKI, aby sa vydala cestou násilnej revolúcie. Aidit, vodca PKI, bol Maov obdivovateľ
        a pripravoval v krajine vojenský puč. 30. septembra 1965 vojenský vodca Suharto tento puč zmaril. Prerušil
        vzťahy s Čínou a zbavil sa mnohých členov indonézskej komunistickej strany. Dôvodom čistky straníkov bol výrok
        premiéra PKI Čou En-laja, ktorý počas jedného medzinárodného zjazdu komunistických krajín sľúbil Sovietskemu
        zväzu a predstaviteľom ďalších komunistických krajín: „V krajinách juhovýchodnej Ázie je veľa zahraničných
        Číňanov. Čínska vláda môže cez nich vyviezť komunizmus do týchto krajín a juhovýchodná Ázia tak môže cez noc
        zmeniť farbu.“ Na základe pokusov ČKS vyvolať miestnu revolúciu v Indonézii začali vznikať rozsiahle protičínske
        hnutia.<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Podobne to bolo aj s protičínskym hnutím v Barme (známa ako Mjanmarsko). V roku 1967, krátko po začiatku
        kultúrnej revolúcie, začal čínsky konzulát v Barme a miestna pobočka tlačovej agentúry Sin-chua so silnou
        propagáciou kultúrnej revolúcie medzi tamojšími Číňanmi. Povzbudzovali študentov k noseniu Maových odznakov,
        študovaniu jeho Červenej knižky a ku konfrontácii s Barmskou vládou. Vojenská chunta vedená generálom Ne Winom
        vydala príkaz, aby bolo nosenie odznakov s Maovým vyobrazením a študovanie Maových textov postavené mimo zákon.
        Taktiež zatvorili miestne čínske školy. V&nbsp;júni 1967 sa v hlavnom meste Rangúne odohrali protičínske
        nepokoje, v ktorých boli ubité na smrť desiatky Číňanov a stovky ich bolo zranených.</p>
      <p>V júli 1967 vyzývali oficiálne médiá ČKS k „pevnej podpore barmského ľudu vedeného Komunistickou stranou Barmy,
        aby začal ozbrojený konflikt a vzburu proti vláde Ne Wina.“ Čoskoro potom vyslala ČKS vojenský poradenský tím,
        aby pomáhal Komunistickej strane Barmy, ktorú barmské vládne sily vytlačili do lesa. 1. januára 1968&nbsp; veľké
        množstvo čínskych Červených gárd a jednotky barmskej komunistickej strany spoločne napadli Barmu z provincie
        Jün-nan, porazili Barmské vládne vojská a prevzali kontrolu nad kokangským regiónom.<a href="#_edn11"
                                                                                               id="_ednref11">[11]</a>
      </p>
      <p>V období kultúrnej revolúcie sa ČKS pokúšala o vyvážanie revolúcie, vrátane propagácie násilia a poskytovania
        vojenského výcviku, munície a financií. Keď sa strana prestala o vývoz revolúcie pokúšať, komunistické strany sa
        v rozličných krajinách rozpadli a neboli schopné obnovy.</p>
      <p>V roku 1961 sa Komunistická strana Malajzie (KSM) rozhodla upustiť od ozbrojeného konfliktu, a namiesto toho
        získať politickú moc prostredníctvom legálnych volieb. Teng Siao-pching si zavolal vodcu KSM, China Penga, a
        ostatných, do Pekingu, kde ich žiadal, aby pokračovali vo svojom úsilí o násilné povstanie, pretože v&nbsp;tom
        čase ČKS verila, že príliv revolúcii koncentrovaných okolo vietnamského bojiska čoskoro zasiahne celú
        juhovýchodnú Áziu. KSM pokračovala v ozbrojenom boji a pokúšala sa o revolúciu počas ďalších 20 rokov.<a
          href="#_edn12" id="_ednref12">[12]</a> ČKS naďalej KSM financovala a&nbsp;nechala ju obstarať si zbrane na
        čiernom trhu v Thajsku. V januári 1969 založila v provincii Chu-nan v meste I-jang rozhlasovú stanicu Malajský
        zvuk revolúcie, ktorá vysielala v malajčine, čínštine, tamilčine, angličtine a ďalších jazykoch.<a
          href="#_edn13" id="_ednref13">[13]</a></p>
      <p>Okrem vyššie spomínaných krajín sa ČKS pokúšala vyvážať revolúciu tiež na Filipíny, do Nepálu, Indie, Srí
        Lanky, Japonska a ďalších krajín. V niektorých prípadoch poskytovala vojenský výcvik a niekedy šírila
        propagandu. Niektoré z týchto komunistických organizácií sa neskôr stali medzinárodne známymi teroristickými
        skupinami. Napríklad japonská Červená armáda, založená v roku 1971, mala korene v radikálnom hnutí 60. rokov a
        stala sa známou svojou revolučnou propagandou násilia a boja proti monarchii. Bola zodpovedná za množstvo
        teroristických útokov, vrátane niekoľkých únosov lietadiel a masakru na letisku Lod.</p>
      <p>Na konci 70. rokov, po kultúrnej revolúcii, zmenšila ČKS svoju podporu komunistickým hnutiam v juhovýchodnej
        Ázii. Počas stretnutí singapurského premiéra Lee Kuan Yewa a Teng Siao-pchinga, požadoval Lee, aby Teng zastavil
        rozhlasové vysielanie pre KSM a&nbsp;pre indonézsku komunistickú stranu. V tej dobe bola ČKS obklopená
        nepriateľmi a&nbsp;bola izolovaná, Teng práve prevzal moc a vyžadoval medzinárodnú podporu, takže súhlasil s
        Leeovou žiadosťou. Teng sa stretol s vodcom KSM Chin Pengom a stanovil dátum ukončenia vysielania agitujúceho za
        komunistickú revolúciu.<a href="#_edn14" id="_ednref14">[14]</a></p>
      <h2>2. Vyvážanie revolúcie do Latinskej Ameriky a Afriky</h2>
      <p>Sovietsky zväz aj ČĽR viedli rozsiahle kampane na podporu komunistických hnutí na Blízkom východe, v južnej
        Ázii, Afrike a Latinskej Amerike. Koncom 60. rokov, Sovietsky zväz, ktorý sa ocitol pod tlakom politiky
        obmedzovania zo strany Spojených štátov a NATO, prijal novú ideologickú líniu zvoľňovania. Táto politika
        vyzývala na mierové spolužitie s krajinami západného kapitalizmu, čo viedlo Sovietsky zväz k zníženiu podpory
        revolučným hnutiam tretieho sveta. ČKS, ktorá káže globálnu revolúciu, obvinila Sovietov z „revizionizmu“. Na
        začiatku šesťdesiatych rokov 20. storočia Wang Ťia-siang, minister oddelenia medzinárodných vzťahov ústredného
        výboru ČKS a bývalý veľvyslanec ČĽR v Sovietskom zväze, navrhol podobnú politiku, Mao ju však kritizoval za
        príliš priateľskú voči imperialistom, revizionistom a reakcionárom a&nbsp;označil ju za nedostatočne podporujúcu
        svetové revolučné hnutie.</p>
      <p>&nbsp;</p>
      <p>Počas kultúrnej revolúcie ČKS často používala slogan: „Proletariát sa môže oslobodiť iba oslobodením celého
        ľudstva.“ V roku 1965 Lin Piao, vtedajší minister národnej obrany, vo svojom článku „Nech žije víťazstvo ľudovej
        vojny!“ tvrdil, že nastáva vysoký príliv svetovej revolúcie. Podľa Maovej teórie „obkľučovania miest z
        vidieckych oblastí“ (čo bol spôsob, akým sa ČKS chopila moci v Číne), prirovnával Lin Severnú Ameriku a západnú
        Európu k mestám a Áziu, Afriku a Latinskú Ameriku k vidieckym oblastiam. Vyvážanie revolúcie do Ázie, Afriky a
        Latinskej Ameriky sa teda stalo pre čínsku komunistickú stranu dôležitým politickým a ideologickým cieľom,
        pretože to kládlo základy pre dobývanie Západu. Preto ČKS pod vedením Mao Ce-tunga, okrem vyvážania revolúcie do
        Ázie, súťažila so Sovietskym zväzom o vplyv v Afrike a Latinskej Amerike.</p>
      <h3>a) Latinská Amerika</h3>
      <p>Profesor Čcheng Jing-chung z Univerzity v Delaware je autorom článku „Vyvážanie revolúcie do sveta: Výskumná
        analýza vplyvu kultúrnej revolúcie v Ázii, Afrike a Latinskej Amerike“, v ktorom uvádza:</p>
      <p><em>Maoistickí komunisti v Latinskej Amerike v polovici 60. rokov založili organizácie v Brazílii, Peru,
        Bolívii, Kolumbii, Čile, Venezuele a Ekvádore. Hlavnými členmi organizácií boli mladí ľudia a študenti. S
        podporou Číny založili maoisti v roku 1967 v Latinskej Amerike dve partizánske skupiny: Ľudovú oslobodzovaciu
        armádu Kolumbie, zahŕňajúcu ženskú skupinu s názvom Jednotka María Cano, ktorá bola napodobeninou Červenej
        detašovanej jednotky žien. Druhou boli Bolivijskí partizáni Ñancahuazov čiže Národná oslobodzovacia armáda
        Bolívie. V rovnakej dobe niektorí komunisti vo Venezuele tiež rozpútali ozbrojené násilné akcie.</em></p>
      <p><em>Navyše vodca Komunistickej strany Peru, Abimael Guzmán, podstúpil v 60. rokoch výcvik v Pekingu. Okrem
        štúdia trhavín a strelných zbraní bolo najdôležitejšie, aby mal správne pochopenie myšlienok Mao Ce-tunga,
        konkrétne o tom, že „duch sa premieňa na hmotu“ a že nastúpením správnej cesty môže človek postúpiť od toho, že
        „nemá personál“, k tomu , že „personál má“, a prejsť od „nevlastnenia zbraní k vlastneniu zbraní“.<a
          href="#_edn15" id="_ednref15">[15]</a></em></p>
      <p>Guzmán bol vodcom Komunistickej strany Peru (tiež známej ako Žiarivá cesta), ktorá bola vládou USA, Japonska,
        Kanady, EÚ a Peru označená za teroristickú organizáciu.</p>
      <p>Kuba bola prvou krajinou Latinskej Ameriky, ktorá nadviazala diplomatické styky s ČKS. Aby si ČKS získala Kubu
        a zároveň aby získala navrch nad Sovietskym zväzom vo vedení medzinárodného komunistického hnutia, ČKS v
        novembri 1960 poskytla Che Guevarovi počas jeho návštevy Číny pôžičku vo výške 60 miliónov dolárov. Bolo to v
        čase, keď Číňania umierali od hladu počas kampane Veľký skok vpred. Čou En-laj tiež Guevarovi povedal, že sa
        môžu dohodnúť na upustení od splatenia pôžičky. Keď sa neskôr Fidel Castro začal prikláňať k Sovietskemu zväzu
        potom, čo sa rozpadli čínsko-sovietske vzťahy, rozoslala ČKS prostredníctvom veľvyslanectva v Havane kubánskym
        úradníkom a civilistom značné množstvo propagandistických letákov, čím sa pokúsila podnietiť prevrat proti
        režimu Fidela Castra.<a href="#_edn16" id="_ednref16">[16]</a></p>
      <p>V roku 1972 nadviazalo Mexiko a ČKS diplomatické vzťahy a za čínskeho veľvyslanca v&nbsp;Mexiku bol menovaný
        agent ČKS Siung Siang-chuej. Cieľom bolo zhromažďovať informácie (vrátane informácií o Spojených štátoch) a
        zasahovať do mexickej vlády. Len týždeň predtým, než Siung Siang-chuej nastúpil do úradu, oznámilo Mexiko
        zatknutie skupiny „partizánov vycvičených v Číne“. Mexický prezident Luis Echeverrí bol obzvlášť vyvedený z
        miery, pretože pri formovaní diplomatických vzťahov s Čínou musel čeliť tvrdej opozícii zvnútra Mexika ako aj zo
        Spojených štátov. Siung navrhol Čou En-laj-ovi, aby zmiernil incident tým, že pozve Echeverría na návštevu Číny.
        Echeverría prijal výzvu a požiadal, aby ČKS poskytla Mexiku obchodné výhody, s čím ČKS súhlasila.<a
          href="#_edn17" id="_ednref17">[17]</a></p>
      <h3>b) Afrika</h3>
      <p>Čcheng Jing-chung v článku „Vyvážanie revolúcie do sveta“ tiež popisuje, ako ČKS ovplyvňuje nezávislosť
        afrických krajín a akou cestou sa tieto krajiny vydali po získaní nezávislosti:</p>
      <p><em>Podľa správ západných médií n</em><em>iektorí členovia africkej revolučnej mládeže z Alžírska, Angoly,
        Mozambiku, Guiney, Kamerunu a Konga absolvovali v prvej polovici 60. rokov školenie v Harbine, Nan-ťingu a
        ďalších čínskych mestách. Člen Zimbabwianskej africkej národnej únie (ZANU) opísal jednoročný tréning, ktorý
        absolvoval v Šanghaji. Okrem vojenského výcviku išlo predovšetkým o politické štúdie o tom, ako zmobilizovať
        obyvateľov vidieka a začať partizánsku vojnu s cieľom rozpútania ľudovej vojny.<a href="#_edn18"
                                                                                          id="_ednref18">[18]</a></em>
      </p>
      <p>Pomoc v Tanzánii a Zambii bola v šesťdesiatych rokoch minulého storočia najväčším zo zahraničných revolučných
        projektov ČKS na tomto kontinente.</p>
      <p>Strana poslala veľkú skupinu expertov z odboru textilného priemyslu v Šanghaji, aby vybudovali textilnú továreň
        v Tanzánii. Vedúci skupiny vkladal do pomocného projektu silný ideologický podtón. Po príchode na stavenisko
        vyvesil čínsku červenú vlajku ČĽR, postavil sochu Mao Ce-tunga a dosku s Maovými citátmi, púšťal hudbu z obdobia
        kultúrnej revolúcie a citoval Maove citáty. Stavenisko textilnej továrne sa stalo vzorom kultúrnej revolúcie v
        zámorí. Tiež organizoval propagandistický tím na podporu myšlienky Mao Ce-tunga a aktívne rozširoval názory
        o&nbsp;vzbure medzi tanzanskými robotníkmi. Vláda Tanzánie nebola z týchto pokusov komunistickej strany vyvážať
        miestnu revolúciu nadšená.</p>
      <p>Následne sa Mao rozhodol vybudovať železnicu Tanzánia – Zambia, ktorá by taktiež prepojila východnú, strednú a
        južnú Afriku. Práce prebiehali od roku 1970 do roku 1976, pričom Čína vyslala päťdesiattisíc robotníkov a minula
        takmer desať miliárd jüanov na výstavbu 320 mostov a 22 tunelov železnice. Ekvivalentné náklady vynaložené na
        železnici dnes by zodpovedali stovkám miliárd čínskych jüanov alebo až desiatkam miliárd amerických dolárov. V
        dôsledku zlého a skorumpovaného vedenia v Tanzánii a v Zambii sa železnica nestala nikdy ziskovou a&nbsp;vo
        svojom fungovaní je naďalej závislá od čínskej pomoci.</p>
      <h2>3. Vyvážanie revolúcie do východnej Európy</h2>
      <p>Po porážke nacistov v druhej svetovej vojne Sovietsky zväz obsadil východné Nemecko, podľa dohody o rozdelení
        moci stanovenej na konferencii v Jalte. Moskva vytvorila komunistické režimy vo všetkých východoeurópskych
        krajinách pod jej kontrolou a vytvorila vojenskú alianciu Varšavskej zmluvy.</p>
      <p>Ako pokračovala studená vojna, Sovietsky zväz sa snažil udržať si dominantné postavenie nad svojimi satelitnými
        štátmi. Po čínsko-sovietskom rozdelení sa začala ČĽR hľadať cesty k východoeurópskym režimom, najmä k
        balkánskemu Albánsku.</p>
      <h3>a) Sovietska represia ľudových hnutí vo východnej Európe</h3>
      <p>Vo februári 1956 sovietsky vodca Nikita Chruščov verejne odsúdil Stalina v tajnom príhovore na 20. kongrese
        Komunistickej strany Sovietskeho zväzu, čím spustil obdobie obmedzenej politickej liberalizácie. Uvoľnená
        atmosféra viedla k povstaniam vo východnej Európe, najskôr v Poľsku a potom v Maďarsku.</p>
      <p>&nbsp;</p>
      <p>V Poľsku sa po smrti de facto diktátora Bolesława Bieruta v marci 1956 jeho nástupcovia začali snažiť o reformu
        a o prerušenie stalinistického dedičstva. V júni štrajkovali desaťtisíce robotníkov v Poznani. Po brutálnom
        potlačení demonštrantov vedenie strany podniklo kroky na upokojenie ľudu, keďže narastal nacionalistický
        sentiment. Za vodcu strany bol zvolený Władysław Gomułka, ktorý sa tvrdo staval voči Sovietskemu zväzu a bol
        ochotný postaviť sa proti Chruščovovi.</p>
      <p>&nbsp;</p>
      <p>V októbri 1956 sa uskutočnil pokus o revolúciu v Maďarsku, spustený skupinou študentov, ktorí napísali zoznam
        šestnástich požiadaviek vrátane stiahnutia sovietskych vojsk. 23. októbra demonštranti prevrátili bronzovú sochu
        Stalina a nechali iba jeho topánky, do ktorých dav umiestnil maďarské vlajky. Ulice obsadilo približne 200 000
        demonštrantov. Sovietske tanky a jednotky začali strieľať do davu ľudí a zabili mnoho neozbrojených
        demonštrantov.</p>
      <p>Sovietsky zväz si pôvodne želal spolupracovať s novovzniknutou opozičnou stranou a menoval Imre Nagya za
        predsedu Rady ministrov a predsedu vlády. Potom, čo sa Nagy dostal k moci, odstúpil od Varšavskej zmluvy a ďalej
        presadzoval liberalizáciu. V reakcii na to 4. novembra Sovieti vyslali tanky a 60 000 vojakov, aby potlačili
        hnutie za nezávislosť, pričom zabili niekoľko tisíc ľudí. Nagy bol zajatý a nakoniec popravený spolu so stovkami
        jeho priaznivcov. Stovky tisíc Maďarov utiekli na Západ.<a href="#_edn19" id="_ednref19">[19]</a></p>
      <p>Po maďarskom povstaní nasledovala v roku 1968 československá pražská jar. Po Chruščovovom príhovore v roku 1956
        začala Komunistická strana Československa (KSČ) pomaly zvoľňovať obmedzenia a po niekoľko nasledujúcich rokov
        vznikala relatívne nezávislá občianska spoločnosť. Jednou z popredných osobností bol Václav Havel, ktorý sa
        neskôr v roku 1993 stal prezidentom&nbsp; dnešnej Českej republiky.</p>
      <p>Na pozadí týchto spoločenských zmien sa 5. januára 1968 ujal postu predsedu vlády KSČ reformista Alexander
        Dubček, ktorý posilnil zmeny a propagoval slogan „socializmu s ľudskou tvárou“. Čoskoro potom začal Dubček vo
        veľkej miere rehabilitovať osoby, ktoré boli za Stalinovej éry nespravodlivo prenasledované. Disidenti boli
        prepustení, bola uvoľnená kontrola nad médiami a&nbsp;podporovala sa akademická sloboda, občania mohli cestovať
        do zahraničia, dohľad nad náboženstvom bol znížený. Najdôležitejšie bolo, že KSČ umožnila obmedzenú
        vnútrostranícku demokraciu.</p>
      <p>Sovietsky zväz, v&nbsp;spomienke na maďarské povstanie z roku 1956, považoval tieto reformy za zradu princípov
        socializmu, ale tiež sa obával, že ostatné krajiny budú Československo nasledovať. Od marca do augusta roku 1968
        usporiadali vodcovia Sovietskeho zväzu vrátane generálneho tajomníka KSSZ Brežneva s Dubčekom päť stretnutí na
        najvyššej úrovni a snažili sa ho prinútiť k upusteniu od demokratických reforiem. Dubček požiadavky odmietol. V
        dôsledku toho v auguste Sovietsky zväz a ďalšie krajiny Varšavskej zmluvy obsadili vojská s&nbsp;počtom niekoľko
        stotisíc vojakov Československo. Pražská jar bola rozdrvená a „socializmus s ľudskou tvárou“ prestal
        existovať.<a href="#_edn20" id="_ednref20">[20]</a></p>
      <p>Sovietsky zväz sa spoliehal na svoju vojenskú silu, aby si vynútil komunistickú vládu vo východnej Európe a
        udržal si nad touto oblasťou kontrolu. Aj tie najmenšie kroky smerom k liberalizácii viedli k rebéliám proti
        socialistickému systému. Koncom osemdesiatych rokov sa sovietske vedenie pustilo do politických a ekonomických
        reforiem, ktoré viedli ku koncu studenej vojny, pádu Berlínskeho múra a rozpadu všetkých komunistických režimov
        vo východnej Európe. Keďže Sovietsky zväz už nechcel pokračovať vo finančne nákladnej politike, ktorá udržovala
        jeho dominanciu, ľudia v Poľsku, Rumunsku, Bulharsku, Československu a vo východnom Nemecku povstávali vo
        všeobecne pokojných protestoch proti miestnym režimom.</p>
      <p>4. júna 1989, v deň masakru na Námestí nebeského pokoja v Číne, sa v Poľsku uskutočnilo prvé kolo slobodných
        demokratických volieb. Druhé kolo, ktoré sa konalo 18. júna, vylúčilo komunistov a ich koaličných partnerov z
        parlamentu.</p>
      <p>V októbri 1989 zažilo niekoľko miest vo východnom Nemecku masové demonštrácie proti vládnucej Zjednotenej
        Socialistickej strane Nemecka (SED). Počas tohto mesiaca navštívil Berlín sovietsky vodca Michail Gorbačov a
        generálnemu tajomníkovi SED Erichovi Honeckerovi povedal, že reforma je jedinou cestou vpred.</p>
      <p>Okamžite potom východné Nemecko uvoľnilo cestovné obmedzenia do Maďarska a Československa, ktoré na základe
        sovietskeho príkladu prechádzali vlastnou politickou liberalizáciou. To umožnilo obrovskému počtu ľudí utiecť
        cez Československo do západného Nemecka a berlínsky múr už ďalej nedokázal vzdorovať náporu utekajúcich občanov.
        9. novembra 1989 sa SED vzdala kontroly nad hranicou medzi Východným a&nbsp;Západným Nemeckom. Desiatky tisíc
        obyvateľov Východného Nemecka odchádzali do Západného Berlína a&nbsp;múr bol postupne rozoberaný. Symbol
        komunistickej železnej opony, ktorá bola zatiahnutá desiatky rokov, sa stal minulosťou.<a href="#_edn21"
                                                                                                  id="_ednref21">[21]</a>
      </p>
      <h3>b) Albánsko a Čína</h3>
      <p>ČKS vynaložila veľké úsilie na získanie si vplyvu nad Albánskom, ktoré predtým kritizovalo Moskvu a opustilo
        Varšavskú zmluvu. Mao bol potešený prerušením stykov Albánska so Sovietmi, a tak začal program poskytovania
        „pomoci“ Albánsku bez ohľadu na náklady.</p>
      <p>Spravodajská agentúra Sin-chua (Xinhua, Nová Čína) uviedla, že „Od roku 1954 do roku 1978 poskytla Čína 75-krát
        finančnú pomoc albánskej Strane práce. Celková suma predstavovala viac ako 10 miliárd čínskych jüanov.“ V tom
        čase malo Albánsko len asi dva milióny ľudí, čo znamenalo, že každý obyvateľ dostal ekvivalent piatich tisíc
        čínskych jüanov. Na druhej strane, priemerný HDP obyvateľa Číny v tej dobe bol len dvesto jüanov. V tomto období
        bola Čína zároveň postihnutá kampaňou Veľkého skoku vpred a následným hladomorom a tiež ekonomickým kolapsom
        spôsobeným Maovou kultúrnou revolúciou. Počas veľkého hladomoru využila Čína svoje extrémne nízke devízové
        ​​rezervy na dovoz potravín. V roku 1962 albánsky veľvyslanec Reis Malile vycestoval do Číny, aby požadoval
        poľnohospodársku pomoc. Čínska loď, ktorá prevážala pšenicu nakúpenú v Kanade do Číny, z nariadenia Liou
        Šao-čchiho zmenila smer plavby a vyložila celý náklad v albánskom prístave.<a href="#_edn22"
                                                                                      id="_ednref22">[22]</a></p>
      <p>Čína navyše pomohla Albánsku postaviť textilnú továreň, ale Albánsko nemalo žiadnu bavlnu, takže Čína musela
        využiť svoje devízové ​​rezervy na nákup bavlny pre Albánsko. Pri jednej príležitosti požiadal viceprezident
        Albánska Adil Carcano vtedajšieho čínskeho veľvyslanca v Albánsku Tí Piaa, aby zariadil výmenu hlavných
        zariadení v továrni na výrobu hnojív a požadoval, aby zariadenie pochádzalo z Talianska. Čína potom skutočne
        nákup strojov z Talianska zaistila a v Albánsku ich nainštalovala. Všetku spomínanú pomoc ČKS považovalo
        Albánsko za samozrejmosť a zbytočne ju premrhalo. Obrovské množstvo ocele, strojových zariadení a presných
        prístrojov zaslaných z Číny bolo ponechaných vonku napospas počasiu. Albánski predstavitelia si s&nbsp;tým
        nerobili starosti a verili tomu, že ak sa nejaká vec zničí alebo stratí, Čína im jednoducho pošle ďalšie.</p>
      <p>V roku 1974 požadovalo Albánsko od Číny pôžičku vo výške päť miliárd jüanov. Čína sa vtedy nachádzala v
        neskorej fáze kultúrnej revolúcie a čínska ekonomika sa už takmer úplne zrútila. Nakoniec sa Čína napriek tomu
        rozhodla Albánsku požičať jednu miliardu jüanov. Albánske vedenie bolo veľmi nespokojné s výškou pôžičky&nbsp; a
        začalo na svojom území protičínske hnutie sa sloganmi: „Nikdy sa neskloníme pred ekonomickým tlakom zahraničnej
        krajiny.“ Takisto odmietla podporiť Čínu dodávkami ropy a asfaltu.</p>
      <h2>4. Komunizmus po studenej vojne</h2>
      <p>Po revolúciách v roku 1989 prešiel samotný Sovietsky zväz drastickými politickými zmenami. V auguste 1991
        predstavitelia tvrdej línie z KSSZ, KGB a armády, ktorí považovali Gorbačovove reformy za zradu komunizmu,
        uskutočnili puč, umiestnili sovietskeho vodcu do domáceho väzenia a poslali tanky, aby obsadili Moskvu. Puč však
        nemal podporu bežných členov strany ani širokej verejnosti a sprisahanci boli zatknutí alebo spáchali
        samovraždu. 25. decembra 1991, keď v celej krajine rástli hnutia za nezávislosť, oznámil Gorbačov rozpad
        Sovietskeho zväzu na pätnásť nezávislých republík.</p>
      <p>&nbsp;</p>
      <p>Zdalo sa, že koniec studenej vojny, kolaps sovietskeho bloku a začatie hospodárskych reforiem v Číne
        signalizujú koniec komunistickej hrozby pre slobodný svet a ľudstvo. V skutočnosti rozpory medzi Spojenými
        štátmi a Sovietskym zväzom odvrátili pozornosť ľudí od intríg ČKS, čo jej poskytlo desiatky rokov, aby posilnila
        svoj totalitný systém a podkopala slobodný svet.</p>
      <p>&nbsp;</p>
      <p>Povojnové hnutie po druhej svetovej vojne a verejné súdne procesy s nacistickými vojnovými zločincami odhalili
        zlo fašistickej ideológie, čo sa odrazilo vo vedomí a vzdelávaní ľudí. Avšak na rozdiel od fašizmu, komunistické
        zločiny neboli doteraz plne zdokumentované a odsúdené. Rusko a mnoho ďalších bývalých sovietskych republík sa
        nikdy neočistilo od svojej sovietskej minulosti a ani nezrušilo svoj aparát tajnej polície. Bývalý agent KGB,
        ktorý neskôr pôsobil ako šéf ruskej tajnej polície, sa teraz stal prezidentom krajiny. Komunistické ideológie a
        ich nasledovníci sú nielen aktívni, ale rozširujú svoj vplyv na Západ a do celého sveta.</p>
      <p>Antikomunistickí aktivisti na Západe – staršia generácia, ktorá má hlbšie pochopenie komunizmu – postupne
        vymierajú, a členovia novej generácie nemajú dostatočné znalosti o komunizme. Komunistické a ľavicové
        organizácie na celom svete boli schopné pokračovať vo svojich radikálnych alebo progresívnych hnutiach
        zameraných na zvrhnutie a zničenie tradičných hodnôt a spoločenských štruktúr.</p>
      <p>Prvý prezident Ruskej federácie, Boris Jeľcin, podnikol určité kroky na zbavenie sa sovietskej ideológie – dal
        prepustiť bývalých sovietskych štátnych zamestnancov, zbúrať sochy Lenina a ďalších komunistických vodcov a
        obnoviť pravoslávne kresťanské kostoly zničené KSSZ – tieto kroky sa však do značnej miery ukázali ako povrchné
        ohľadom očistenia krajiny od tak hlboko zakorenenej straníckej kultúry, ktorá sa vštepovala ľuďom a inštitúciám
        takmer sedem desaťročí. Okrem toho politické nepokoje a hospodársky kolaps, ktorý nasledoval po rozpade
        Sovietskeho zväzu, vyvolali nostalgiu nad minulosťou.</p>
      <p>Oživenie ľudovej podpory komunizmu v Rusku viedlo k vytvoreniu Komunistickej strany Ruskej federácie (KSRF). Tá
        sa stala hlavnou politickou stranou, až kým ju nenahradila súčasná Putinova strana Jednotné Rusko.</p>
      <p>V októbri 1993 – iba dva roky potom, ako občania Moskvy vyšli do ulíc, aby požadovali nezávislosť a demokraciu
        – demonštrovali na Červenom námestí desaťtisíce obyvateľov Moskvy, ktorí volali mená Lenina a Stalina a mávali
        bývalými sovietskymi vlajkami. V nedávnych prieskumoch verejnej mienky, ako napríklad prieskum uskutočnený
        moskovskou televíziou RBK v roku 2015, mnoho respondentov (približne 60 percent v prieskume RBK) uviedlo, že
        Sovietsky zväz by mal byť obnovený. V máji 2017 Komunistický zväz mládeže, ktorý bol založený ako pridružená
        organizácia KSSZ, usporiadal na moskovskom Červenom námestí pred Leninovou hrobkou slávnostnú prísahu pre
        mladých ľudí. Na zhromaždení predseda KSRF Gennadij Zjuganov vyhlásil, že do strany nedávno vstúpilo
        šesťdesiattisíc nových členov a že komunistická strana aj naďalej žije a rozširuje sa.</p>
      <p>Duch komunizmu stále prenasleduje najväčšiu krajinu na svete. Len v samotnej Moskve existuje viac ako
        osemdesiat Leninových pamätníkov. Leninova hrobka na Červenom námestí naďalej priťahuje turistov
        a&nbsp;Leninových nasledovníkov. Zločiny KGB neboli svetu nikdy úplne odhalené a odsúdené. V priebehu minulého
        storočia sa vo vládach väčšiny krajín stratil zjavný vplyv komunizmu. Na vrchole komunistického hnutia za
        studenej vojny boli viac ako dve desiatky krajín, kde vládli komunistické režimy. V súčasnosti zostali iba
        štyri: Čína, Vietnam, Kuba a Laos. Hoci vládnuca strana v Severnej Kórei marxizmus-leninizmus na povrchu
        opustila, v skutočnosti ide stále o komunistický totalitný štát.&nbsp; Vo viac ako sto krajinách sveta sú
        komunistické strany zaregistrované.</p>
      <p>V osemdesiatych rokoch bolo v Latinskej Amerike viac ako päťdesiat komunistických strán, pričom celkový počet
        ich členov predstavoval jeden milión (z toho asi polovica bola z Komunistickej strany Kuby). V prvej polovici
        osemdesiatych rokov 20. storočia prebiehala prudká súťaž medzi Spojenými štátmi a Sovietskym zväzom v
        nepokojných oblastiach Latinskej Ameriky a Ázie. S rozpadom východnej Európy a Sovietskeho zväzu začali postupne
        ubúdať komunistické strany – ako bola Komunistická strana Peru&nbsp; – ktoré sa sústreďovali na presadzovanie
        svojej moci násilím.</p>
      <p>Vo väčšine krajín Latinskej Ameriky sa však vláda transformovala do rôznych druhov socializmu. Ľavicové
        politické strany prevzali mená ako Demokratická socialistická strana a Ľudová socialistická strana. Niekoľko
        komunistických strán v Strednej Amerike odstránilo zo svojich mien slovo „komunistická strana“, ale naďalej
        propagovalo komunistické a socialistické ideológie, čím sa vo svojej činnosti stali ešte podvodnejšími.</p>
      <p>Vo väčšine z 33 nezávislých krajín Latinskej Ameriky a Karibiku sú komunistické strany legitímnymi politickými
        stranami. Vo Venezuele, Čile, Uruguaji a inde často komunistická strana a vládnuca strana vytvoria koaličnú
        vládu, pričom komunistické strany niektorých iných krajín hrajú úlohu opozície.</p>
      <p>Komunizmus na Západe a v iných oblastiach po celom svete sa neuchýlil k násilným metódam, tak ako to urobil na
        Východe. Namiesto toho pomocou zástancov ľavicových ideológií skryte prenikal takmer do všetkých aspektov
        spoločnosti. Za&nbsp;niekoľko desiatok rokov sa západným formám komunizmu podarilo rozvrátiť tradičnú spoločnosť
        a morálku, čím zničili kultúru, ktorú ľudia dostali z nebies. V tomto zmysle si duch komunizmu získal kontrolu
        nad celým svetom.</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Chongyi Feng, „How the Chinese Communist Party Exerts Its Influence in
        Australia,“ <em>ABC News</em> (Australia), June 5, 2017, <a
          href="http://www.abc.net.au/news/2017-06-06/how-china-uses-its-soft-power-strategy-in-australia/8590610"></a><a
          href="http://www.abc.net.au/news/2017-06-06/how-china-uses-its-soft-power-strategy-in-australia/8590610">http://www.abc.net.au/news/2017-06-06/how-china-uses-its-soft-power-strategy-in-australia/8590610</a>.
      </p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Jung Chang and Jon Halliday, „Lukewarm Believer (1920–25; age 26–31),“
        Mao: The Unknown Story (New York: Anchor Books, 2006).</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Harry Truman, „Statement on Formosa“ (príhovor, White House, 5. januára
        1950), USC US–China Institute, z&nbsp;9. apríla 2020, <a
          href="https://china.usc.edu/harry-s-truman-%E2%80%9Cstatement-formosa%E2%80%9D-january-5-1950"></a><a
          href="https://china.usc.edu/harry-s-truman-%E2%80%9Cstatement-formosa%E2%80%9D-january-5-1950">https://china.usc.edu/harry-s-truman-%E2%80%9Cstatement-formosa%E2%80%9D-january-5-1950</a>.
      </p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> „US Enters the Korean Conflict,“ („USA vstupuje do kórejského
        konfliktu“) National Archives, naposledy zmenené 7. septembra 2016, <a
          href="https://www.archives.gov/education/lessons/korean-conflict.">https://www.archives.gov/education/lessons/korean-conflict.</a>
      </p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Qian Yaping 錢亞平, „60 nian lai Zhongguo de dui wai yuanzhu: zui duo shi
        zhan guojia caizheng zhichu 7%“ 60年來中國的對外援助：最多時佔國家財政支出7% („60 rokov čínskej zahraničnej pomoci: Až 7 % národných
        fiškálnych výdajov“), <em>Ľudový denník<em>,</em></em> 27. mája 2011, <a
          href="http://history.people.com.cn/BIG5/205396/14757192.html"></a><a
          href="http://history.people.com.cn/BIG5/205396/14757192.html">http://history.people.com.cn/BIG5/205396/14757192.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Chen Xianhui 陈宪辉, „Di 38 zhang kang Mei yuan Chao“ 第38章 抗美援朝 („Kapitola
        38: Odporovanie USA, pomoc Kórei“), zo Geming de zhenxiang. Ershi shiji Zhongguo jishi 革命的真相.二十世纪中国纪事 (<em>Pravda
          o&nbsp;revolúcii – Kronika Číny 20.&nbsp;storočia</em>) (december 2014), <a
          href="https://www.bannedbook.net/forum2/topic6605.html"></a><a
          href="https://www.bannedbook.net/forum2/topic6605.html">https://www.bannedbook.net/forum2/topic6605.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Zhong Shanluo 鐘山洛, Dangshi mimi 黨史秘密 <em>(„</em><em>Tajomstvá histórie
        strany<em>“)</em></em> (Taiwan: Ha Ye chubanshe, 2016). (v čínštine)</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Chen Xianhui 陈宪辉, „Di 52 zhang Wen Ge wai jiao: duiwai shuchu geming“
        第52章 文革外交与输出革命 („Kapitola 52: Kultúrna revolúcia, diplomacia a export revolúcie“), zo Geming de zhenxiang. Ershi
        shiji Zhongguo jishi 革命的真相.二十世纪中国纪事 <em>(Pravda o&nbsp;revolúcii – Kronika Číny
          20.&nbsp;storočia)</em> (december 2014), <a href="https://www.bannedbook.net/forum2/topic6605.html"></a><a
          href="https://www.bannedbook.net/forum2/topic6605.html">https://www.bannedbook.net/forum2/topic6605.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Li Su 李肃, „Jie mi shi ke: taoli Chaoxian wangming Zhongguo
        (wanzhengban)“ 解密时刻：逃离朝鲜 亡命中国 (完整版 ) („Moment odhalenia: Útek zo Severnej Kórey, umieranie v&nbsp;Číne“), <em>Voice
          of America,</em> 8. októbra 2012, <a
          href="https://www.voachinese.com/a/hm-escaping-north-korea-20121007/1522169.html"></a><a
          href="https://www.voachinese.com/a/hm-escaping-north-korea-20121007/1522169.html">https://www.voachinese.com/a/hm-escaping-north-korea-20121007/1522169.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Song Zheng 宋征, „1965 Yinni ‘9.30’ zhengbian shimo“ 1965印尼『9.30』政政变始末
        („Prevrat 30.&nbsp;septembra 1965 v&nbsp;Indonézii“), China in Perspective, z&nbsp;20. septembra 2017, <a
          href="http://www.chinainperspective.com/ArtShow.aspx?AID=183410"></a><a
          href="http://www.chinainperspective.com/ArtShow.aspx?AID=183410">http://www.chinainperspective.com/ArtShow.aspx?AID=183410</a>.
        (v čínštine)</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Wang Nan 王南, „Shuo gu lun jin: Miandian de Zhongguo chongjidui bo“,
        说古论今：缅甸的中国冲击波 („Rozhovory o minulosti: Čínska vlna otrasov v&nbsp;Mjanmarsku“), <em>Voice of America,</em> 24.
        februára&nbsp; 2012, <a
          href="https://www.voachinese.com/a/article-2012024-burma-china-factors-iv-140343173/812128.html"></a><a
          href="https://www.voachinese.com/a/article-2012024-burma-china-factors-iv-140343173/812128.html">https://www.voachinese.com/a/article-2012024-burma-china-factors-iv-140343173/812128.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Cheng Yinghong 程映虹, „Xiang shijie shuchu geming—„Wen Ge“ zai Ya Fei
        La de yingxiang chutan“ 向世界输出革命──“文革”在亚非拉的影响初探 („Vyvážanie revolúcie do sveta – Rané skúmanie dopadu kultúrnej
        revolúcie v&nbsp;Ázii, Afrike a&nbsp;Latinskej Amerike“), <em>Modern China Studies,</em> vol. 3 (2006). (v
        čínštine)</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Chen Yinan 陳益南, „She zai Zhongguo de Ma Gong diantai“, 設在中國的馬共電台
        („Rozhlasová stanica MCP v&nbsp;Číne“), <em>Yanhuang Chunqiu,</em> vol. 8, 2015. (v čínštine)</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Cheng, „Xiang shijie shuchu geming.“ („Vyvážanie revolúcie do sveta“)
      </p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Na rovnakom mieste.</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Chen, „Di 52 zhang Wen Ge wai jiao.“</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Hanshan 寒山, „Jin shi zuo fei: Xiong Xiangshi he Zhonggong zai La Mei
        shuchu geing de lishi“ 今是昨非﹕熊向暉和中共在拉美輸出革命的歷史(„Siung Siang-chuej a&nbsp;história vyvážania revolúcie
        Komunistickou stranou Číny do Latinskej Ameriky“), <em>Radio Free Asia,</em> 17. novembra 2005, <a
          href="https://www.archives.gov/education/lessons/korean-conflic"></a><a
          href="https://www.rfa.org/cantonese/features/history/china_cccp-20051117.html">https://www.rfa.org/cantonese/features/history/china_cccp-20051117.html</a>.
        (v čínštine)</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Cheng, „Xiang shijie shuchu geming.“</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> Chen Kuide 陈奎德, Jindai xianfa de yanhua 近代宪政的演化 <em>(</em>„Vývoj
        súčasného konštitucionalizmu“<em>)</em>, The Observer (2007), chap. 60. (v čínštine)</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Na rovnakom mieste, kapitola 67.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Na rovnakom mieste, kapitola 77.</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Wang Hongqi, „Zhongguo dui Aerbaniya de yuanzhu“ 中国对阿尔巴尼亚的援助 („Čínska
        pomoc v&nbsp;Albánsku”), <em>Yanhuang Chunqiu</em>, zo 16. apríla 2020, <a
          href="http://www.yhcqw.com/36/3172.html#.">http://www.yhcqw.com/36/3172.html#.</a> (v čínštine)</p>
      <Link to={`/kapitola_05`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


